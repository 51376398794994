<template>
  <div class="day-legend" v-bind="$attrs" v-on="$listeners">
    <hr class="planning" :class="{ rounded: rounded }" :style="planningBarStyle" />
    <span v-if="withTitle">{{ $t("pages.permission.hours_planed") }}</span>
  </div>
</template>

<script>
export default {
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    planningBarStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.planning {
  height: 5px;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: $color-primary;
}

.rounded {
  border-radius: 5px;
}
</style>
